.swatch-attribute-label {
  font-weight: 700;
  position: relative;
  margin-right: spacers(2);
}
.swatch-attribute-label.required {
  padding-right: 10px;
}
.swatch-attribute-label[data-required='1']:after {
  color: #e02b27;
  content: '*';
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  right: -11px;
  top: -2px;
}
.swatch-attribute-selected-option {
  color: #646464;
  padding-left: 17px;
  display: none !important;
}
.swatch-attribute-options {
  margin-top: 10px;
}
.swatch-attribute-options:focus {
  box-shadow: none;
}
.swatch-attribute-options .swatch-option-tooltip-layered .title {
  color: #282828;
  bottom: -5px;
  height: 20px;
  left: 0;
  margin-bottom: 10px;
  position: absolute;
  text-align: center;
  width: 100%;
}
.swatch-attribute.size .swatch-option,
.swatch-attribute.manufacturer .swatch-option {
  background: #f0f0f0;
  color: #949494;
}
.swatch-attribute.size .swatch-option.selected,
.swatch-attribute.manufacturer .swatch-option.selected {
  background: #ffffff;
  border: 1px solid #ffffff;
  color: #000000;
}
.swatch-option {
  border: 1px solid #dadada;
  cursor: pointer;
  float: left;
  height: 20px;
  margin: 0 10px 5px 0;
  max-width: 100%;
  min-width: 30px;
  overflow: hidden;
  padding: 1px 2px;
  position: relative;
  text-align: center;
  text-overflow: ellipsis;
}
.swatch-option:focus {
  box-shadow: 0 0 3px 1px #00699d;
}
.swatch-option.text {
  background: #f0f0f0;
  color: #686868;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  margin-right: 7px;
  min-width: 22px;
  padding: 4px 8px;
}
.swatch-option.text.selected {
  background-color: #ffffff !important;
}
.swatch-option.selected {
  outline: 2px solid #ff5501;
  border: 1px solid #ffffff;
  color: #333333;
}
.swatch-option:not(.disabled):hover {
  border: 1px solid #ffffff;
  color: #333333;
  outline: 1px solid #999999;
}
.swatch-option.image:not(.disabled):hover,
.swatch-option.color:not(.disabled):hover {
  border: 1px solid #ffffff;
  outline: 2px solid #c34000;
}
.swatch-option.disabled {
  cursor: default;
}
.swatch-option.disabled:after {
  background: linear-gradient(to left top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 42%, #ffffff 43%, #ffffff 46%, #ff5216 47%, #ff5216 53%, #ffffff 54%, #ffffff 57%, rgba(255, 255, 255, 0) 58%, rgba(255, 255, 255, 0) 100%);
  background: -moz-linear-gradient(to left top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 42%, #ffffff 43%, #ffffff 46%, #ff5216 47%, #ff5216 53%, #ffffff 54%, #ffffff 57%, rgba(255, 255, 255, 0) 58%, rgba(255, 255, 255, 0) 100%);
  background: -ms-linear-gradient(to left top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 42%, #ffffff 43%, #ffffff 46%, #ff5216 47%, #ff5216 53%, #ffffff 54%, #ffffff 57%, rgba(255, 255, 255, 0) 58%, rgba(255, 255, 255, 0) 100%);
  background: -o-linear-gradient(to left top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 42%, #ffffff 43%, #ffffff 46%, #ff5216 47%, #ff5216 53%, #ffffff 54%, #ffffff 57%, rgba(255, 255, 255, 0) 58%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(42%, rgba(255, 255, 255, 0)), color-stop(43%, #ffffff), color-stop(46%, #ffffff), color-stop(47%, #ff5216), color-stop(53%, #ff5216), color-stop(54%, #ffffff), color-stop(57%, #ffffff), color-stop(58%, rgba(255, 255, 255, 0)), color-stop(100%, rgba(255, 255, 255, 0)));
  background: -webkit-linear-gradient(to left top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 42%, #ffffff 43%, #ffffff 46%, #ff5216 47%, #ff5216 53%, #ffffff 54%, #ffffff 57%, rgba(255, 255, 255, 0) 58%, rgba(255, 255, 255, 0) 100%);
  bottom: 0;
  content: '';
  filter: 'progid:DXImageTransform.Microsoft.gradient(startColorstr=#00ffffff, endColorstr=#00ffffff, GradientType=1)';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.swatch-option-disabled {
  border: 0;
  cursor: default;
  outline: none !important;
}
.swatch-option-disabled:after {
  -webkit-transform: rotate(-30deg);
  -ms-transform: rotate(-30deg);
  transform: rotate(-30deg);
  content: '';
  height: 2px;
  left: -4px;
  position: absolute;
  top: 10px;
  width: 42px;
  z-index: 995;
  background: #e02b27;
}
.swatch-option-loading {
  // content: url('../../images/loader-2.gif');
}
.swatch-option-tooltip {
  border: 1px solid #dadada;
  color: #949494;
  display: none;
  max-height: 100%;
  min-height: 20px;
  min-width: 20px;
  padding: 5px;
  position: absolute;
  text-align: center;
  z-index: 999;
  background: #ffffff;
}
.swatch-option-tooltip .corner,
.swatch-option-tooltip-layered .corner {
  bottom: 0;
  height: 8px;
  left: 40%;
  position: absolute;
}
.swatch-option-tooltip .corner:before,
.swatch-option-tooltip-layered .corner:before,
.swatch-option-tooltip .corner:after,
.swatch-option-tooltip-layered .corner:after {
  border-style: solid;
  content: '';
  font-size: 1px;
  height: 0;
  position: relative;
  width: 0;
}
.swatch-option-tooltip .corner:before,
.swatch-option-tooltip-layered .corner:before {
  border-color: #adadad transparent transparent transparent;
  border-width: 8px 8.5px 0 8.5px;
  left: 0;
  top: 2px;
}
.swatch-option-tooltip .corner:after,
.swatch-option-tooltip-layered .corner:after {
  border-color: #ffffff transparent transparent transparent;
  border-width: 7px 7.5px 0 7.5px;
  left: -15px;
  top: 1px;
}
.swatch-option-tooltip .image,
.swatch-option-tooltip-layered .image {
  display: block;
  height: 130px;
  margin: 0 auto;
  width: 130px;
}
.swatch-option-tooltip-layered {
  border: 1px solid #dadada;
  color: #949494;
  background: #ffffff;
  display: none;
  left: -47px;
  position: absolute;
  width: 140px;
  z-index: 999;
}
.swatch-option-tooltip .title {
  color: #282828;
  display: block;
  max-height: 200px;
  min-height: 20px;
  overflow: hidden;
  text-align: center;
}
.swatch-option-link-layered {
  margin: 0 !important;
  padding: 0 !important;
  position: relative;
}
.swatch-option-link-layered:focus > div {
  box-shadow: 0 0 3px 1px #00699d;
}
.swatch-option-link-layered:hover > .swatch-option-tooltip-layered {
  display: block;
}
.swatch-opt {
  margin: 20px 0;
}
.swatch-opt-listing {
  margin-bottom: 10px;
}
.swatch-more {
  display: inline-block;
  margin: 2px 0;
  position: static;
  text-decoration: none !important;
  z-index: 1;
}
.swatch-visual-tooltip-layered {
  height: 160px;
  top: -170px;
}
.swatch-textual-tooltip-layered {
  height: 30px;
  top: -40px;
}
.swatch-input {
  left: -1000px;
  position: absolute;
  visibility: hidden;
}

.swatch-attribute-options:before,
.swatch-attribute-options:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}

.swatch-attribute-options:after {
  clear: both;
}

